// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-opf-funding-alternative-js": () => import("./../../../src/pages/article/opf-funding-alternative.js" /* webpackChunkName: "component---src-pages-article-opf-funding-alternative-js" */),
  "component---src-pages-article-opf-get-funded-fast-js": () => import("./../../../src/pages/article/opf-get-funded-fast.js" /* webpackChunkName: "component---src-pages-article-opf-get-funded-fast-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styleguide-index-js": () => import("./../../../src/pages/styleguide/index.js" /* webpackChunkName: "component---src-pages-styleguide-index-js" */),
  "component---src-pages-test-test-template-landing-page-advertorial-js": () => import("./../../../src/pages/test/test-template-landing-page-advertorial.js" /* webpackChunkName: "component---src-pages-test-test-template-landing-page-advertorial-js" */),
  "component---src-pages-test-test-template-landing-page-search-js": () => import("./../../../src/pages/test/test-template-landing-page-search.js" /* webpackChunkName: "component---src-pages-test-test-template-landing-page-search-js" */)
}

